import { MessageTypes } from './constants';
import { getPageMeta, isEmailSharePage } from './utils/cms';
import { debugLog, isDebugEnabled } from './utils/debug';
import { getEmbedPlaceholderEl, hasDocLoaded, hasParentWithClass, insertPreloaderIframe } from './utils/dom';
import { renderEmbeddedPlayer, postMessageToPlayers, setupPlayerMessageListener, updatePlayerOptions } from './utils/embed';
import { isAuthedAppPreview, isQa } from './utils/env';
import { reportError, reportMessage } from './utils/errors';
import { objectValues } from './utils/object';
import { setupCspViolationListener, setupFormSubmittedListener, setupMutationObserver } from './utils/observe';
import { findPlaceholderImages, getServerRenderedPlayers } from './utils/placeholders';
import { uniqueId } from './utils/uniqueId';
import { getPerformanceMetrics } from './utils/performance';
let initialized = false;
export default function createLoader({
  testingMode = false
} = {}) {
  const _hsq = window._hsq = window._hsq || [];
  const _hsp = window._hsp = window._hsp || [];
  const queuedPlayers = [];
  const players = {};
  let privacyConsent;
  let utk;
  const isExistingEmbed = embedId => Boolean(players[embedId]);
  const isQueuedEmbed = embedId => queuedPlayers.find(([id]) => embedId === id);
  function registerPlayer(...args) {
    let id;
    let options;
    let placeholderEl;
    reportMessage('registerPlayer still being called');
    if (typeof args[0] === 'string') {
      id = args[0];
      options = args[1];
      options.embedId = id || uniqueId();
    } else if (args[0] instanceof HTMLElement) {
      placeholderEl = args[0];
      options = args[1];
      id = options.embedId || uniqueId();
    } else if (typeof args[0] === 'object') {
      options = args[0];
      id = options.embedId || uniqueId();
    } else {
      console.warn('invalid args passed to `registerPlayer`', args);
      return;
    }
    if (isQueuedEmbed(id) || isExistingEmbed(id)) {
      debugLog(`Player with embedId: ${id} already exists, ignoring call to registerPlayer`, options);
      return;
    }
    if (hasDocLoaded()) {
      if (!placeholderEl && options.embedId) {
        placeholderEl = getEmbedPlaceholderEl(id);
      }
      if (placeholderEl) {
        renderPlayer(placeholderEl, options);
        return;
      }
    }
    queuedPlayers.push([id, options]);
  }
  function areAnalyticsAllowed() {
    if (!privacyConsent) {
      return false;
    }
    return privacyConsent.categories ? privacyConsent.categories.analytics : privacyConsent.allowed;
  }
  function sendInitialMessages(embedId) {
    postMessageToPlayers({
      [embedId]: players[embedId]
    }, MessageTypes.SET_PAGE_META, getPageMeta());
    if (utk && areAnalyticsAllowed() && !isAuthedAppPreview()) {
      postMessageToPlayers({
        [embedId]: players[embedId]
      }, MessageTypes.SET_UTK, {
        utk
      });
    }
  }
  function initPlayer(embedId, options, placement) {
    players[embedId] = renderEmbeddedPlayer(embedId, options, placement);
    if (!isAuthedAppPreview()) {
      sendInitialMessages(embedId);
    }
    return players[embedId];
  }
  function renderPlayer(idOrElement, options) {
    let id;
    let placeholderEl;
    if (typeof idOrElement === 'string') {
      id = idOrElement;
      placeholderEl = getEmbedPlaceholderEl(id);
      if (!placeholderEl) {
        console.warn(`Element id ${idOrElement} not found`);
        return null;
      }
    } else if (idOrElement instanceof HTMLElement) {
      id = options.embedId || uniqueId();
      placeholderEl = idOrElement;
    } else {
      console.warn(`First argument to renderPlayer must be an id string or HTMLElement`);
      return null;
    }
    if (isExistingEmbed(id)) {
      debugLog(`Player with embedId: ${id} already exists, ignoring call to renderPlayer`, options);
      return null;
    }
    return initPlayer(id, options, {
      placeholderEl
    });
  }
  function updatePlayer(embedId, options) {
    const player = getPlayer(embedId);
    if (!player) {
      debugLog(`Could not find player id ${embedId} to update`);
      return null;
    }
    updatePlayerOptions(player, options);
    return player;
  }
  function onTrackingCodeLoaded(_utk) {
    utk = _utk;
    if (!privacyConsent || !utk) {
      debugLog('Could not obtain utk cookie and privacyConsent', utk, privacyConsent);
      return;
    }
    if (areAnalyticsAllowed()) {
      debugLog(`Received utk ${utk} privacyConsent`, JSON.stringify(privacyConsent));
      postMessageToPlayers(players, MessageTypes.SET_UTK, {
        utk
      });
    } else {
      debugLog('Received privacyConsent but not allowed', utk, privacyConsent);
      postMessageToPlayers(players, MessageTypes.SET_UTK, null);
    }
  }
  function listenForPrivacyConsent() {
    _hsp.push(['addPrivacyConsentListener', _privacyConsent => {
      privacyConsent = _privacyConsent;
      _hsq.push(['addUserTokenListener', onTrackingCodeLoaded]);
    }]);
  }
  function getPlayers() {
    return players;
  }
  function getPlayer(embedId) {
    return getPlayers()[embedId];
  }
  function getPlayerMessages() {
    return objectValues(players).reduce((acc, player) => {
      player.receivedMessages.forEach(playerMessage => {
        acc.push([player.id, playerMessage[0], playerMessage[1]]);
      });
      return acc;
    }, []);
  }
  function pauseAllPlayers() {
    objectValues(players).forEach(p => p.triggerPause());
  }
  function renderPlaceholders() {
    findPlaceholderImages().forEach(({
      el,
      options
    }) => {
      if (options.embedId) {
        options.renderContext = hasParentWithClass(el, '.widget-type-rich_text') ? 'rich-text-placeholder' : 'onload-placeholder';
        if (isEmailSharePage()) {
          options.renderContext = 'share-page';
        }
        initPlayer(options.embedId, options, {
          placeholderEl: el
        });
      }
    });
    getServerRenderedPlayers().forEach(({
      el,
      options
    }) => {
      if (options.embedId) {
        options.renderContext = isEmailSharePage() ? 'hubl-iframe-share-page' : 'hubl-iframe';
        initPlayer(options.embedId, options, {
          placeholderEl: el
        });
      }
    });
  }
  function setupPreloader() {
    // timeout is arbitrary, but enough so that a player initially visible in the viewport should start to load first,
    // meaning we can skip adding the preloader iframe
    setTimeout(() => {
      const anyVisiblePlayer = Object.values(getPlayers()).find(player => player.confirmedVisible);
      if (anyVisiblePlayer) {
        debugLog('Not inserting preloader iframe as at least one player is already visible & loading:', anyVisiblePlayer);
      } else {
        insertPreloaderIframe(() => {
          Object.values(getPlayers()).forEach(player => {
            player.preloaderIframeLoaded = true;
          });
        });
      }
    }, 500);
  }
  function onLoad() {
    queuedPlayers.forEach(([id, options]) => {
      initPlayer(id, options);
    });
    setupPlayerMessageListener(players);
    initialized = true;
    if (!isAuthedAppPreview()) {
      setupPreloader();
      listenForPrivacyConsent();
      renderPlaceholders();
      setupCspViolationListener();
      setupFormSubmittedListener();
      setupMutationObserver();
      if (window._hsVideoReady && Array.isArray(window._hsVideoReady)) {
        setTimeout(() => window._hsVideoReady.forEach(callback => callback()));
      }
    }
  }
  if (initialized && !testingMode) {
    debugLog('loader already initialized, not listening for document load');
  } else {
    if (hasDocLoaded()) {
      try {
        onLoad();
      } catch (err) {
        reportError(err, {
          source: 'already-loaded'
        });
      }
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        try {
          onLoad();
        } catch (err) {
          reportError(err, {
            source: 'DOMContentLoaded'
          });
        }
      });
      // dispatched by renderer for content previews, which intentionally exclude analytics JS
      window.addEventListener('content-analytics-code-not-loaded', () => onTrackingCodeLoaded(null));
    }
  }
  const apiExport = {
    registerPlayer,
    renderPlayer,
    updatePlayer,
    getPlayer,
    getPlayers: () => objectValues(getPlayers()),
    getPerformanceMetrics: () => getPerformanceMetrics(Object.values(players)),
    pauseAllPlayers
  };
  if (testingMode || isDebugEnabled() || isQa()) {
    return Object.assign({}, apiExport, {
      getPlayerMessages,
      triggerLoad: onLoad,
      // likely will expose always, not quite ready
      renderPlaceholders
    });
  }
  return apiExport;
}